import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">            
            <li className="with-megamenu"><Link to="/corporate">Delivery solutions</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/">Restaurant</Link></li>
                                    <li><Link to="/">e-Commerce</Link></li>
                                    <li><Link to="/">Grocery</Link></li>
                                    <li><Link to="/">Documents</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li className="has-droupdown"><Link to="/corporate">Software solutions</Link></li>

            <li className="has-droupdown"><Link to="">Become a partner</Link></li>

        </ul>
    )
}
export default Nav;
