import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Delivery Managment Software',
        description: 'Manage your orders and couriers in real-time. Communicate with them, assign and modify tasks. You can work with many pickup points (restaurants). System will build an optimal route for every delivery process.'
    },
    {
        icon: <FiCast />,
        title: 'Delivery Service for Business',
        description: 'Check availability of the service in your location and use our external delivery service option. Our driver comes to pick up your order and deliver it to the client. You pay for the service when you sell orders.'
    },
    {
        icon: <FiMap />,
        title: 'Courier Mobile Phone Application',
        description: 'Make sure your couriers can easily reach customers they are delivering to. Minimize communications frictions with real time tracking and allow multiple order pick-up from one or more location.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b2 color-gray mb--4" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;