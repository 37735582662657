import React from 'react';
import VideoTwo from '../elements/video/VideoTwo';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import BrandThree from '../elements/brand/BrandThree';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";
import ContactOne from "../elements/contact/ContactOne";


const PopupData = [
    {
        id: "01",
    }
]



const EventConference = () => {
    return (
        <>
            <SEO title="Deliver 4U" />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-750">
                    <div className="container">
                        <div className="row row--10 align-items-center">
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                            <ServiceOne 
                            serviceStyle = "service__style--1"
                            textAlign = "text-center"
                            />
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Delivery Partners"
                                    description = ""
                                />
                            </div>
                                <BrandThree brandStyle="brand-style-2" />
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />



                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default EventConference;
