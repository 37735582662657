import React from 'react';
import SEO from "../../common/SEO";

import SectionTitle from '../sectionTitle/SectionTitle';
import ContactOne from './ContactOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FiArrowRight } from 'react-icons/fi';


const Contact = () => {
    return (
        <>
            <SEO title="Deliver 4U" />

<main className="page-wrapper">
    <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">On demand delivery</h1>
                                    <p className="description color-white">We deliver professional software service for you.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border"><Link to="/corporate">How it works</Link><i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact"
                                        title = "You can find us here."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
    <FooterTwo />
    <Copyright />
</main>

</>
)
}
export default Contact;