import React from 'react';
import VideoTwo from '../elements/video/VideoTwo';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceThree from '../elements/service/ServiceThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import BrandThree from '../elements/brand/BrandThree';
import { FiArrowRight } from "react-icons/fi";
import {Link} from "react-router-dom";
import ContactOne from "../elements/contact/ContactOne";


const PopupData = [
    {
        id: "01",
    }
]



const EventConference = () => {
    return (
        <>
            <SEO title="Deliver 4U" />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">On demand delivery</h1>
                                    <p className="description color-white">We deliver professional software service for you.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border"><Link to="/corporate">How it works</Link><i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                {PopupData.map((item) => (
                                    <div className="video-btn" key={item.id}>
                                        <VideoTwo imagename="./images/about/about-6.png" galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFour image="./images/about/about-4.png" />
  
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our services"
                                    title = "On Demand Delivery"
                                    description = ""
                                />
                            </div>
                        </div>
                        <ServiceThree 
                            serviceStyle = "service__style--2"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>
                {/* End Elements Area  */}

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact"
                                        title = "You can find us here."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>


                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Delivery Partners"
                                    description = ""
                                />
                            </div>
                                <BrandThree brandStyle="brand-style-2" />
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />



                <FooterTwo />
                <Copyright />
            </main>

        </>
    )
}

export default EventConference;
